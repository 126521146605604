/* Define styles for the loader container */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Center vertically on the viewport */
  }
  
  /* Define styles for the loader element */
  .loader {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Loader border */
    border-top: 4px solid #3498db; /* Loader border color */
    border-radius: 50%; /* Make it round */
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite; /* Animation to spin the loader */
  }
  
  /* Keyframes for the spinning animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  