@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Anton&family=Space+Grotesk:wght@300..700&display=swap") body {
  margin: 0;
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* styles.css */
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.section {
  height: 100vh;
  /* Adjust the height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
}

code {
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.home-logo {
  background-image: url(../public//images/logo_breaking1.png);
  height: 42px;
  /* width: 177px; */
  width: 150px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.home-banner {
  background-image: url(../public//images/home-banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  aspect-ratio: 9/4;
}


.footer-logo img {
  width: 50%;
  display: inline;
}

.bg-header-clone {
  clip-path: polygon(0% 0%, 100% 0%, 40px 0%, 0% 100%);
  @apply bg-[#181b22];
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.bg-logo {
  background-image: url(../public/images/logo-bg.svg);
  aspect-ratio: 20/1;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-work-with {
  background-image: url(../public/images/sign_work.png);
  aspect-ratio: 3/1;
  background-repeat: no-repeat;
  background-size: cover;
}

input {
  @apply bg-transparent;
  @apply outline-none;
}

.bg-sponsor {
  background-image: url(../public//images/sponsor.png);
  aspect-ratio: 2/3;
  @apply w-full;
}

.bg-sponsors {
  background-image: url(../public//images/sponsors.png);
  aspect-ratio: 5/1;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-advertisement {
  background-image: url(../public//images/advertisement.png);
  aspect-ratio: 5/1;
  background-repeat: no-repeat;
  background-size: cover;
}

h1 {
  font-size: 28px;
  font-weight: bold;
  padding: 10px 10px;
}

p {
  padding: 10px 0;
}

.main-container {}

.toggle-bg:after {
  content: "";
  @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-5 w-5 transition shadow-sm;
}

input:checked+.toggle-bg:after {
  transform: translateX(100%);
  @apply border-white;
}

input:checked+.toggle-bg {
  @apply bg-blue-600 border-blue-600;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

/* Customize scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  z-index: 100;
  /* Adjust z-index if needed */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fec749;
  border-radius: 5px;
  height: 150px;
}

.calendar-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.calendar {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
}

.row {
  display: flex;
  width: 100%;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.days {
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px;
}

.cell {
  position: relative;
  height: 80px;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

.cell.disabled {
  color: #ccc;
}

.cell.available {
  background-color: #e6f7ff;
}

.number {
  position: absolute;
  top: 4px;
  left: 4px;
}

.available-slots {
  position: absolute;
  bottom: 4px;
  right: 4px;
  background-color: #1890ff;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-page {
  height: 50vh;
  width: 100%;

  aspect-ratio: 9/4;
}




@media screen and (min-width: 300px) {

  .hero-page {
    height: 70vh;
    width: 100%;
    /* aspect-ratio: 9/4; */
  }
}

@media screen and (min-width: 376px) {

  .hero-page {
    height: 50vh;
    width: 100%;
    /* aspect-ratio: 9/4; */
  }
}

@media screen and (min-width: 750px) {

  .hero-page {
    height: 40vh;
    width: 100%;
    /* aspect-ratio: 9/4; */
  }
}

@media screen and (min-width: 1024px) {
  .hero-page {
    height: 60vh;
    width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .hero-page {
    height: 90vh;
    width: 100%;
  }
}